<template>
  <div id="main">
    <h1>rooger个人站</h1>
    <h2>网站导引</h2>
    <div v-for="section in sections" :key="section.title" class="section">
      <div>
        <div class="title">{{ section.title }}</div>
        <div class="intro">{{ section.intro }}</div>
      </div>
      <div class="button">
        <div class="mask"></div>
        <div class="trigger" @click="jump(section.href)">前往</div>
      </div>
    </div>
    <footer id="footer">
      <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2024083922号</a> | rooger个人站
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      sections: [{
        title: "wordIn",
        href: "/wordin",
        intro: "一个单词默写器"
      },
      {
        title: "LyricFinder",
        href: "/lf",
        intro: "听喜欢的歌"
      },
      {
        title: "blog",
        href: "https://blog.cast1e.top",
        intro: "我的个人博客"
      },
      {
        title: "toolbox",
        href: "https://toolbox.cast1e.top",
        intro: "rooger的工具箱"
      }
      ]
    }
  },
  methods: {
    jump(location) {
      window.location.href = location
    }
  },
  components: {
  },
  created() {
    const html = document.querySelector("html");
    const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)");
    if (isDarkTheme.matches) html.classList.add("dark");
    isDarkTheme.addEventListener('change', (event) => {
      if (event.matches) {
        html.classList.add("dark");
      } else {
        html.classList.remove("dark");
      }
    });
  }
}
</script>

<style>
html {
  --bg-color: #fbfbfb;
  --text-color: #464646;
  --bd-color: #bbbbbb99;
}

html.dark {
  --bg-color: #2a2a2a;
  --text-color: #c0c0c0;
  --bd-color: #7f7f7f7c;
}
body{
  background-color: var(--bg-color);
  color: var(--text-color);
  text-align: center;
  padding: 0 10% 0 10%;
  font-size: 18px;
}
#main{
  text-align: left;
}
#footer {
  text-align: center;
}

.section {
  border-radius: 5px;
  border: solid 1px var(--bd-color);
  margin-bottom: 25px;
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: .5s;
  background-color: var(--bg-color);
  height: 80px;
}

.section:hover {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, .32);
}

.section .title {
  font-size: 35px;
}

.section .intro {
  font-size: 12px;
}

.section .button {
  width: 70px;
  height: 40px;
  position: relative;
}

.section .button * {
  width: 100%;
  height: 100%;
  position: absolute;
}

.section .button .trigger {
  border: solid 1px var(--bd-color);
  color: var(--text-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color);
}

.section .button .mask {
  background-image: linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%);
  filter: blur(10px);
  transition: .5s;
  opacity: 0;
}

.section .button:hover {
  cursor: pointer;
}

.section .button:hover .mask{
  opacity: 1;
}

a{
  color: var(--text-color)s;
}
</style>
